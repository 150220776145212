import { deleteHook, getHook, postHook, putHook } from "@/hooks/useAxios";
import router from "@/router";

export default {
  state: {
    isLoggedIn: localStorage.getItem("isLoggedIn") || false,
    user: {},
    roles: [
      {
        role: "admin",
        name: "Админ",
        url: "/admin/analytics",
      },
      {
        role: "stock",
        name: "Склад",
        url: "/admin/stocks",
      },
      {
        role: "office",
        name: "Менеджер",
        url: "/admin/delivered",
      },
      {
        role: "courier",
        name: "Курьер",
        url: "/admin/deliveries",
      },
      {
        role: "user",
        name: "Пользователь",
        url: "/user",
      },
      {
        role: "partner",
        name: "Партнер",
        url: "/partner",
      },
    ],
    payments: [],
    prices: [],
    regions: [],
    isLoading: false,
    region: localStorage.getItem("region") || "AZ",
    currency_price: 0,
  },
  mutations: {
    SET_AUTH(state, payload) {
      state.isLoggedIn = payload;
    },
    SET_REGION(state, payload) {
      state.region = payload;
    },
    SET_CURRENCY_PRICE(state, payload) {
      state.currency_price = payload;
    },
    SET_REGIONS(state, payload) {
      state.regions = payload;
    },
    SET_PAYMENTS(state, payload) {
      state.payments = payload;
    },
    SET_PRICES(state, payload) {
      state.prices = payload;
    },
    SET_ADS(state, payload) {
      state.ads = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_TOKEN(state, payload) {
      state.accessToken = payload;
    },
    SET_REFRESH_TOKEN(state, payload) {
      state.refreshToken = payload;
    },
    SET_EMAIL(state, payload) {
      state.isVerified = payload;
    },
    LOGOUT(state) {
      (state.isLoggedIn = false),
        (state.user = {}),
        (state.accessToken = null),
        (state.refreshToken = null);
    },
  },
  actions: {
    async authorize({ commit, state }, data) {
      try {
        const res = await postHook("/auth/login", data);
        if (res?.accessToken) {
          localStorage.setItem("user", JSON.stringify(res?.user));
          localStorage.setItem("accessToken", res?.accessToken);
          localStorage.setItem("refreshToken", res?.refreshToken);
          localStorage.setItem("isLoggedIn", true);
          commit("SET_USER", res?.user);
          const role = state.roles.find((i) => i.role === res?.user.role);
          router.push({ path: role.url });
          commit("SET_AUTH", true);
        }
        return res;
      } catch (err) {
        commit("SET_AUTH", false);
        return err;
      }
    },
    async changePassword({ commit, state }, data) {
      try {
        const res = await postHook("/auth/changePass", data);
        if (res?.accessToken) {
          localStorage.setItem("user", JSON.stringify(res?.user));
          localStorage.setItem("accessToken", res?.accessToken);
          localStorage.setItem("refreshToken", res?.refreshToken);
          localStorage.setItem("isLoggedIn", true);
          commit("SET_USER", res?.user);
          const role = state.roles.find((i) => i.role === res?.user.role);
          router.push({ path: role.url });
          commit("SET_AUTH", true);
        }
        return res;
      } catch (err) {
        commit("SET_AUTH", false);
        return err;
      }
    },
    async verifyEmail({ commit }, link) {
      try {
        const res = await getHook(`/auth/activate/${link}`);
        if (res) {
          localStorage.setItem("user", JSON.stringify(res));
          commit("SET_USER", res);
        }
        return res;
      } catch (err) {
        commit("SET_AUTH", false);
        return err;
      }
    },

    async registration({ commit, state }, data) {
      try {
        const res = await postHook("/auth/registration", data);
        if (res?.accessToken) {
          localStorage.setItem("user", JSON.stringify(res?.user));
          localStorage.setItem("accessToken", res?.accessToken);
          localStorage.setItem("refreshToken", res?.refreshToken);
          localStorage.setItem("isLoggedIn", true);
          commit("SET_USER", res?.user);
          const role = state.roles.find((i) => i.role === res?.user.role);
          router.push({ path: role.url });
          commit("SET_AUTH", true);
        }
        return res;
      } catch (err) {
        commit("SET_AUTH", false);
        return err;
      }
    },
    async registrationWithCode({ commit, state }, data) {
      try {
        const res = await postHook("/auth/registration/code", data);
        if (res?.accessToken) {
          localStorage.setItem("user", JSON.stringify(res?.user));
          localStorage.setItem("accessToken", res?.accessToken);
          localStorage.setItem("refreshToken", res?.refreshToken);
          localStorage.setItem("isLoggedIn", true);
          commit("SET_USER", res?.user);
          const role = state.roles.find((i) => i.role === res?.user.role);
          router.push({ path: role.url });
          commit("SET_AUTH", true);
        }
        return res;
      } catch (err) {
        commit("SET_AUTH", false);
        return err;
      }
    },

    async logOut({ commit }) {
      try {
        const res = await postHook("/auth/logout", {});
        if (res) {
          commit("LOGOUT");
          localStorage.removeItem("user");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.setItem("isLoggedIn", false);
          router.push({ path: "/" }).catch(() => {});
        }
      } catch (err) {
        commit("SET_AUTH", false);
        return err;
      }
    },
    async getUser({ commit }) {
      try {
        commit("SET_LOADING", true);
        const res = await getHook("/user", {});
        commit("SET_USER", res);
        commit("SET_LOADING", false);
        return res;
      } catch (e) {
        return e;
      }
    },
    async addUser(_, data) {
      try {
        const res = await postHook("/user/add", data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async updateUser(_, data) {
      try {
        const res = await putHook(`/user/update`, data);
        return res;
      } catch (err) {
        return err;
      }
    },

    async updateProfile(_, data) {
      try {
        const res = putHook(`/user/updateProfile?id=${data.userId}`, {
          address: data.address,
          phone: data.phone,
          user_code: data.user_code,
        });
        return res;
      } catch (err) {
        return err;
      }
    },
    async deleteUser(_, id) {
      try {
        const res = await deleteHook(`/user/delete?id=${id}`);
        return res;
      } catch (err) {
        return err;
      }
    },
    async getUsers(_, data) {
      try {
        const res = await getHook(`/users?${new URLSearchParams(data)}`, {});
        return res;
      } catch (err) {
        return err;
      }
    },
    async getGoods(_, data) {
      try {
        const res = await getHook(`/goods?${new URLSearchParams(data)}`, {});
        return res;
      } catch (err) {
        return err;
      }
    },
    async searchGoods(_, data) {
      try {
        const res = await getHook(
          `/goods/search?${new URLSearchParams(data)}`,
          {},
        );
        return res;
      } catch (err) {
        return err;
      }
    },
    async searchDeliveredGoods(_, data) {
      try {
        const res = await getHook(
          `/goods/delivered/search?${new URLSearchParams(data)}`,
          {},
        );
        return res;
      } catch (err) {
        return err;
      }
    },

    async searchUserGoods(_, data) {
      try {
        const res = await getHook(
          `/goods/user/search?${new URLSearchParams(data)}`,
          {},
        );
        return res;
      } catch (err) {
        return err;
      }
    },
    async searchUserGoodByTrack(_, data) {
      try {
        const res = await getHook(
          `/goods/user/track_code?${new URLSearchParams(data)}`,
          {},
        );
        return res;
      } catch (err) {
        return err;
      }
    },

    async addGood(_, data) {
      try {
        const res = await postHook("/good/create", data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async getGood(_, track_code) {
      try {
        const res = await getHook(`/good?track_code=${track_code}`);
        return res;
      } catch (err) {
        return err;
      }
    },
    async updateGood(_, data) {
      try {
        const res = await putHook("/good/update", data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async updateRestoreGood(_, data) {
      try {
        const res = await putHook("/good/restore/update", data);
        return res;
      } catch (err) {
        return err;
      }
    },

    async deleteGood(_, id) {
      try {
        const res = await deleteHook("/good/delete", { id: id });
        return res;
      } catch (err) {
        return err;
      }
    },

    async importGoods(_, data) {
      try {
        const { status, file } = data;
        let formData = new FormData();
        formData.append("file", file);
        formData.append("status", status);
        const res = await postHook("/goods/import", formData);
        return res;
      } catch (err) {
        return err;
      }
    },
    async uploadAvatar(_, file) {
      try {
        let formData = new FormData();
        formData.append("file", file);
        const res = await postHook("/user/uploadAvatar", formData);
        return res;
      } catch (err) {
        return err;
      }
    },
    async uploadGoods(_, data) {
      try {
        const res = await postHook("/goods/upload", data);
        return res;
      } catch (err) {
        return err;
      }
    },

    async getRegions({ commit }) {
      const result = await getHook("/regions");
      commit("SET_REGIONS", result);
      return result;
    },
    async addRegion(_, data) {
      try {
        const res = await postHook("/region/add", data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async updateRegion(_, data) {
      try {
        const res = await putHook(`/region/update`, data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async deleteRegion(_, id) {
      try {
        const res = await deleteHook(`/region/delete?id=${id}`);
        return res;
      } catch (err) {
        return err;
      }
    },
    async getPayments({ commit }) {
      const result = await getHook("/payments");
      commit("SET_PAYMENTS", result);
      return result;
    },
    async addPayment(_, data) {
      try {
        const res = await postHook("/payment/add", data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async updatePayment(_, data) {
      try {
        const res = await putHook(`/payment/update`, data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async deletePayment(_, id) {
      try {
        const res = await deleteHook(`/payment/delete?id=${id}`);
        return res;
      } catch (err) {
        return err;
      }
    },

    //
    async getPrices({ commit }) {
      const result = await getHook("/prices");
      commit("SET_PRICES", result);
      return result;
    },
    async addPrice(_, data) {
      try {
        const res = await postHook("/price/add", data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async updatePrice(_, data) {
      try {
        const res = await putHook(`/price/update`, data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async deletePrice(_, id) {
      try {
        const res = await deleteHook(`/price/delete?id=${id}`);
        return res;
      } catch (err) {
        return err;
      }
    },
    //
    async getGoodsByUserCode(_, data) {
      try {
        const res = await getHook(
          `/good/user_code?${new URLSearchParams(data)}`,
        );
        return res;
      } catch (err) {
        return err;
      }
    },
    async getGoodsByTrackCodeAndDate(_, data) {
      try {
        const res = await getHook(`/good/date?${new URLSearchParams(data)}`);
        return res;
      } catch (err) {
        return err;
      }
    },
    async findStockGood(_, data) {
      try {
        const res = await getHook(
          `/good/track_code?${new URLSearchParams(data)}`,
        );
        return res;
      } catch (err) {
        return err;
      }
    },
    async updateManyGoods(_, data) {
      try {
        const res = await putHook("/good/updateMany", data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async createPackages(_, data) {
      try {
        const res = await postHook("/package/insertMany", data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async findStockGoodByUser(_, data) {
      try {
        const res = await getHook(`/good/date?${new URLSearchParams(data)}`);
        return res;
      } catch (err) {
        return err;
      }
    },
    async getAnalytics() {
      try {
        const res = await getHook("/analytics/data");
        return res;
      } catch (err) {
        return err;
      }
    },
    async getAnalyticsDetails(_, data) {
      try {
        const res = await getHook(
          `/analytics/details?${new URLSearchParams(data)}`,
        );
        return res;
      } catch (err) {
        return err;
      }
    },
    async addPackage(_, data) {
      try {
        const res = await postHook("/package/add", data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async createPackage(_, data) {
      try {
        const res = await postHook("/package/create", data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async sendReceiveMessages(_, data) {
      try {
        const res = await postHook("/package/send", data);
        return res;
      } catch (err) {
        return err;
      }
    },

    async updatePackage(_, data) {
      try {
        const res = await putHook("/package/update", data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async updateNewPackage(_, data) {
      try {
        const res = await putHook("/package/update_new", data);
        return res;
      } catch (err) {
        return err;
      }
    },

    async paidPackage(_, data) {
      try {
        const res = await putHook(
          `/package/paid?id=${data.id}&sum=${data.sum}`,
          {},
        );
        return res;
      } catch (err) {
        return err;
      }
    },
    async updatePackageData(_, data) {
      try {
        const res = await putHook("/package/updatePackage", data);
        return res;
      } catch (err) {
        return err;
      }
    },

    async deletePackage(_, id) {
      try {
        const res = await deleteHook(`/package/delete?id=${id}`);
        return res;
      } catch (err) {
        return err;
      }
    },
    async getPackageByUserCodeStatus(_, data) {
      try {
        const { status, user_code } = data;
        const res = await getHook(
          `/package/user?user_code=${user_code}&status=${status}`,
        );
        return res;
      } catch (err) {
        return err;
      }
    },
    async getPackageByUserCodeAllStatus(_, data) {
      try {
        const res = await getHook(
          `/package/partner/date?user_code=${data.user_code}&status=${data.status}`,
        );
        return res;
      } catch (err) {
        return err;
      }
    },

    async getPackageByPartnerCodeStatus(_, data) {
      try {
        const res = await getHook(
          `/package/partner?${new URLSearchParams(data)}`,
        );
        return res;
      } catch (err) {
        return err;
      }
    },

    async getPackageByUserStatus(_, data) {
      try {
        const res = await getHook(
          `/user/packages?${new URLSearchParams(data)}`,
        );
        return res;
      } catch (err) {
        return err;
      }
    },
    async getPackageByPartnerStatus(_, data) {
      try {
        const res = await getHook(
          `/partner/packages?${new URLSearchParams(data)}`,
        );
        return res;
      } catch (err) {
        return err;
      }
    },

    async getPackagesUserCodeStatus(_, data) {
      try {
        const { status, user_code } = data;
        const res = await getHook(
          `/package/user/all?user_code=${user_code}&status=${status}`,
        );
        return res;
      } catch (err) {
        return err;
      }
    },

    async getPackageByStatus(_, data) {
      try {
        const res = await getHook(
          `/package/status?${new URLSearchParams(data)}`,
        );
        return res;
      } catch (err) {
        return err;
      }
    },
    async getAllGoodsByDate(_, data) {
      try {
        const res = await getHook(`/good/all?${new URLSearchParams(data)}`);
        return res;
      } catch (err) {
        return err;
      }
    },
    async getUserGoods(_, data) {
      try {
        const res = await getHook(`/good/user?${new URLSearchParams(data)}`);
        return res;
      } catch (err) {
        return err;
      }
    },
    async getPartnerGoods(_, data) {
      try {
        const res = await getHook(`/good/partner?${new URLSearchParams(data)}`);
        return res;
      } catch (err) {
        return err;
      }
    },
    async updateAllData() {
      try {
        const res = await postHook(`/package/updateAll`, {});
        return res;
      } catch (err) {
        return err;
      }
    },
    async updateAllUsers() {
      try {
        const res = await postHook(`/users/update`, {});
        return res;
      } catch (err) {
        return err;
      }
    },

    async downloadRecieved(_, data) {
      try {
        const res = await getHook(
          `/package/download?${new URLSearchParams(data)}`,
        );
        return res;
      } catch (err) {
        return err;
      }
    },
    async deleteMany() {
      try {
        const res = await deleteHook(`/goods/delete/many`);
        return res;
      } catch (err) {
        return err;
      }
    },

    async orderDelivery(_, data) {
      try {
        const res = await postHook(`/delivery/order`, data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async getUserDelivery(_, data) {
      try {
        const res = await getHook(
          `/user/deliveries?${new URLSearchParams(data)}`,
          {},
        );
        return res;
      } catch (err) {
        return err;
      }
    },
    async getDeliveries(_, data) {
      try {
        const res = await getHook(
          `/deliveries?${new URLSearchParams(data)}`,
          {},
        );
        return res;
      } catch (err) {
        return err;
      }
    },
    async getDestinations() {
      try {
        const res = await getHook(`/deliveries/destinations`, {});
        return res;
      } catch (err) {
        return err;
      }
    },

    async changeOrderStatus(_, data) {
      try {
        const res = await putHook(`/delivery/update_status`, data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async cancelOrderStatus(_, id) {
      try {
        const res = await deleteHook(`/delivery/remove?id=${id}`);
        return res;
      } catch (err) {
        return err;
      }
    },

    async addWeightAndCount(_, data) {
      try {
        const res = await putHook(`/delivery/add_weight`, data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async updateDelivery(_, data) {
      try {
        const res = await putHook(`/delivery/update`, data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async getBoxByDate(_, data) {
      try {
        const res = await getHook(`/box/date?${new URLSearchParams(data)}`, {});
        return res;
      } catch (err) {
        return err;
      }
    },
    async updateBox(_, data) {
      try {
        const res = await putHook(`/box/update`, data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async createBox(_, data) {
      try {
        const res = await postHook(`/box/add`, data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async changeRegion({ commit }, data) {
      try {
        if (data) {
          localStorage.setItem("region", data);
          commit("SET_REGION", data);
        }
      } catch (err) {
        commit("SET_REGION", "AZ");
        return err;
      }
    },
    async changeCurrencyPrice({ commit }, data) {
      try {
        if (data) {
          // localStorage.setItem("currency_price", data);
          commit("SET_CURRENCY_PRICE", data);
        }
      } catch (err) {
        commit("SET_CURRENCY_PRICE", 90);
        return err;
      }
    },
    async getCurrencyPrice({ commit }) {
      try {
        const res = await getHook(`/currency`, {});
        if (res && res.price) {
          // localStorage.setItem("currency_price", res.price);
          commit("SET_CURRENCY_PRICE", res.price);
        }
        return res;
      } catch (err) {
        commit("SET_CURRENCY_PRICE", 90);
        return err;
      }
    },
    async updateCurrency({ commit }, data) {
      try {
        const res = await putHook(`/currency/update`, data);
        if (res && res.price) {
          // localStorage.setItem("currency_price", res.price);
          commit("SET_CURRENCY_PRICE", res.price);
        }
        return res;
      } catch (err) {
        commit("SET_CURRENCY_PRICE", 90);
        return err;
      }
    },
    async getNews(_, data) {
      try {
        const res = await getHook(
          `/news${data ? `?${new URLSearchParams(data)}` : ""}`,
          {},
        );
        return res;
      } catch (err) {
        return err;
      }
    },
    async addNews(_, data) {
      try {
        let formData = new FormData();
        if (data.file) formData.append("file", data.file);
        formData.append("title", data.title);
        formData.append("text", data.text);
        formData.append("active", data.active);
        formData.append("link", data.link);
        if (data.photo) formData.append("photo", data.photo);
        if (data.id) formData.append("id", data.id);
        const res = await postHook(`/news`, formData);
        return res;
      } catch (err) {
        return err;
      }
    },
    async updateNews(_, data) {
      try {
        let formData = new FormData();
        formData.append("file", data.file);
        formData.append("title", data.title);
        formData.append("text", data.text);
        formData.append("photo", data.photo);
        formData.append("id", data.id);
        const res = await postHook(`/news`, data);
        return res;
      } catch (err) {
        return err;
      }
    },
    async removeNews(_, id) {
      try {
        const res = await deleteHook(`/news?id=${id}`, {});
        return res;
      } catch (err) {
        return err;
      }
    },
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    user: (state) => state.user,
    roles: (state) => state.roles,
    regions: (state) => state.regions,
    payments: (state) => state.payments,
    prices: (state) => state.prices,
    accessToken: (state) => state.accessToken,
    isLoading: (state) => state.isLoading,
    region: (state) => state.region,
    currencyPrice: (state) => state.currency_price,
  },
};
