import axios from "axios";
import { memoizedRefreshToken } from "./refreshToken";
import { API_URL } from "./config";

axios.defaults.baseURL = API_URL;

axios.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("accessToken");
    const token = accessToken;
    if (accessToken) {
      config.headers = {
        ...config.headers,
        token,
      };
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;
    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;
      const accessToken = await memoizedRefreshToken();
      if (accessToken) {
        config.headers = {
          ...config.headers,
          token: accessToken,
          "Content-type": "application/json",
        };
      }
      return axios(config);
    }
    return Promise.reject(error);
  },
);

export const axiosPrivate = axios;
