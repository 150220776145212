import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueToast from "vue-toast-notification";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru"; // #1284e7
import "vue-toast-notification/dist/theme-bootstrap.css";

Vue.use(DatePicker);
Vue.use(VueToast, {
  // One of the options
  duration: 2500,
  position: "top",
});
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
