import { memoize } from "memoize-lit";
import { axiosPublic } from "./axiosPublic";

const refreshTokenHook = async () => {
  const refresh_token = localStorage.getItem("refreshToken");
  try {
    const response = await axiosPublic.get("api/auth/refresh", {
      headers: {
        token: refresh_token,
      },
    });
    const { accessToken, refreshToken } = response.data;
    if (!accessToken) {
      localStorage.setItem("accessToken", "");
      localStorage.setItem("refreshToken", "");
      localStorage.setItem("isLoggedIn", false);
      window.location.replace("/login");
    }
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("isLoggedIn", true);
    return accessToken;
  } catch (error) {
    localStorage.setItem("accessToken", "");
    localStorage.setItem("refreshToken", "");
    localStorage.setItem("isLoggedIn", false);
    window.location.replace("/login");
  }
};

export const memoizedRefreshToken = memoize(refreshTokenHook, {
  maxAge: 100,
});
