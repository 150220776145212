import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
// import chat from "./chat";

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    auth,
  },
  plugins: [],
});
