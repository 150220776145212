import { axiosPrivate } from "./axiosPrivate";
import { API_URL } from "./config";

export const postHook = async (url, payload) => {
  try {
    const res = await axiosPrivate.post(`${API_URL}/api${url}`, payload);
    return res?.data;
  } catch (e) {
    const error = e?.response?.data;
    return error;
  }
};
export const getHook = async (url, payload) => {
  try {
    const res = await axiosPrivate.get(`${API_URL}/api${url}`, payload);
    return res?.data;
  } catch (e) {
    const error = e?.response?.data;
    return error;
  }
};

export const putHook = async (url, payload) => {
  try {
    const res = await axiosPrivate.put(`${API_URL}/api${url}`, payload);
    return res?.data;
  } catch (e) {
    const error = e?.response?.data;
    return error;
  }
};
export const deleteHook = async (url, payload) => {
  try {
    const res = await axiosPrivate.delete(`${API_URL}/api${url}`, {
      data: payload,
    });
    return res?.data;
  } catch (e) {
    const error = e?.response?.data;
    return error;
  }
};
